import './App.css';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from './Map/Map'
import React from 'react';
import data from './Map/mapstyles.json';
import ConfirmationBox from './ConfirmationBox/ConfirmationBox';
import Logo from './img/app-logo.png';
import BtnIcon from './img/btn-icon.png'
function App() {
  const [myPosition, setMyPosition] = React.useState<GeolocationPosition>();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [defaultLat, setDefaultLat] = React.useState<number>(48.864716);
  const [defaultLng, setDefaultLng] = React.useState<number>(2.349014);
  const defaultZoom = 10;
  const googleMapsStyle = data as google.maps.MapTypeStyle[];

  const render = (status: Status): React.ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <></>;
  }
  

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position){
      setMyPosition(position);
    })
    setDefaultLat(myPosition?.coords.latitude ?? defaultLat);
    setDefaultLng(myPosition?.coords.longitude ?? defaultLng);

  }, []);

  const handleOnClick = () => {
    setOpenDialog(true)
  }

  if (!process.env.REACT_APP_GOOGLE_KEY) {
    return <h2>No Google Maps API key detected</h2>
  }

  return (
    <div className="App">
      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY} render={render}>
        <Map 
          center={{ lat: myPosition?.coords.latitude ?? defaultLat, lng: myPosition?.coords.longitude ?? defaultLng }}  
          zoom={defaultZoom} 
          styles={googleMapsStyle}
        />
      </Wrapper>
      <img className='App-logo' src={Logo}/>
      <button 
        className='add-sticker-button'
        onClick={handleOnClick}
      >
        <img className='btn-icon' src={BtnIcon} />
      </button>
      <ConfirmationBox open={openDialog} onClose={() => setOpenDialog(false)}/>
    </div>
  );
}

export default App;
