import { useEffect, useRef, useState } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { addSticker } from "../api";
import { idText } from "typescript";
import './ConfirmationBox.css';
import Poo from '../img/poo.png';

// Our component will receive center coords and zoom size in props
type ConfirmationBoxProps = {
  onClose: () => void
  open: boolean
}
function ConfirmationBox({ onClose, open }: ConfirmationBoxProps) {
    const [info, setInfo] = useState<string>('')
    const [lat, setLat] = useState<string>('')
    const [lng, setLng] = useState<string>('')
    const id: string = "a random string"
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function(position){
            setLat(position.coords.latitude.toString());
            setLng(position.coords.longitude.toString());
        });
    }, []);
    const handleConfirm = () => {
        const stickerData: ISticker = {
            _id: id,
            info: info ?? "Anonyme",
            lat: lat,
            lng: lng
        }
        addSticker(stickerData).then(() => {
            window.location.reload();
        })
    }

    return (
        <Dialog open={open}>
        <div className="dialog">
                <div className="dialog-title">
                    <div className="title">Sur le trône ?</div>
                </div>
                <div className="gif">
                    <img src={Poo} className="rotating" alt="poo"/>
                </div>
                <div className="dialog-input">
                    <input 
                        value={info} 
                        onChange={(e) => setInfo(e.target.value)} 
                        placeholder="Ton petit nom"
                    />
                </div>
                <div className="dialog-buttons">
                    <button className="main-button" onClick={handleConfirm}>Valider</button>
                    <button className="gray-button main-button " onClick={onClose}>Annuler</button>
                </div>
            </div>
        </Dialog>

    );
}
export default ConfirmationBox