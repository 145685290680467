import axios, { AxiosResponse } from "axios"

const baseUrl = process.env.REACT_APP_HOST_URL

export const getStickers = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const stickers: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + "/api/stickers"
    )
    return stickers
  } catch (error: any) {
    throw new Error(error)
  }
}

export const addSticker = async (
    formData: ISticker
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      const sticker: Omit<ISticker, "_id"> = {
        info: formData.info,
        lat: formData.lat,
        lng: formData.lng,
      }
      const saveSticker: AxiosResponse<ApiDataType> = await axios.post(
        baseUrl + "/api/add-sticker",
        sticker
      )
      return saveSticker
    } catch (error: any) {
      throw new Error(error)
    }
  }