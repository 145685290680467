import { useEffect, useRef, useState } from "react";
import './Map.css'
import { getStickers } from "../api";
import phrases from './phrases.json'

// Our component will receive center coords and zoom size in props
type MapProps = {
  center: google.maps.LatLngLiteral
  zoom: number
  styles?: google.maps.MapTypeStyle[]
}

function Map({ center, zoom, styles }: MapProps) {
  const ref = useRef(null);
  const [map, setMap] = useState<google.maps.Map<Element> | undefined>(undefined)
  const [stickers, setStickers] = useState<ISticker[] | any>([])

  useEffect(() => {
    // we need to save google-map object for adding markers and routes in future
    if (ref.current) {
      // here will connect map frame to div element in DOM by using ref hook
      const createdMap = new window.google.maps.Map(
        ref.current,
        {
          center,
          zoom,
          styles,
          disableDefaultUI: true,
          clickableIcons: false
        }
      );
      setMap(createdMap)
    }
  }, [center, zoom, styles]);

  useEffect(() => {
    getStickers()
    .then(({ data: { stickers } }: ISticker[] | any) => setStickers(stickers))
    .catch((err: Error) => console.log(err))
  }, [])

  const infoWindow = new google.maps.InfoWindow();

  for (const sticker of stickers) {
    const marker = new google.maps.Marker({
      position: { lat: Number(sticker.lat), lng: Number(sticker.lng) }, // Marker position (Paris)
      icon: {
          url: 'https://cdn-icons-png.flaticon.com/512/983/983064.png',
          scaledSize: new google.maps.Size(25, 25), // scaled size
        },
      title: `${sticker.info} ${phrases[Math.floor(Math.random() * phrases.length)].phrase}`,
      map
    });

    marker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(marker.getTitle()!);
      infoWindow.open(marker.getMap()!, marker);
    });
  }

  // map will be connect to this div block
  return <div ref={ref} id="map" className="map"/>;
}

export default Map